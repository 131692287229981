@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "~@angular/cdk/overlay-prebuilt.css";
.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.form-control {
  border: 1px solid #388087;
  border-radius: 30px;
  /* height: 50px; */
  display: flex;
  align-items: center;
}

.form-select {
  border: 1px solid #388087;
  border-radius: 30px;
  /* height: 50px; */
}

.form-control:focus {
  border: 1px solid #388087;
}

html,
body {
  min-height: 100vh;
}

* {
  font-size: 18px;
  font-family: "IBM Plex Sans", sans-serif;
}
.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-family: "IBM Plex Sans", sans-serif !important;
}

.btnsave {
  width: auto;
  height: 50px;
  padding: 0px 30px;
  border-radius: 40px;
  border-color: #388087;
  border: 1px solid #388087;
  background-color: #388087;
  color: #ffffff;
}

.btncancel {
  width: auto;
  height: 50px;
  padding: 0px 30px;
  border-radius: 40px;
  border: 1px solid #388087;
  background-color: #ffffff;
  color: #388087;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btncancel:hover {
  border: 1px solid #ffffff;
  background-color: #388087;
  color: #ffffff;
}

.btndelete {
  width: auto;
  height: 50px;
  padding: 0px 30px;
  border-radius: 40px;
  border: 1px solid #d92323;
  background-color: #ffffff;
  color: #d92323;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btndelete:hover {
  border: 1px solid #ffffff;
  background-color: #d92323;
  color: #ffffff;
}

.btnsavedelete {
  width: auto;
  height: 50px;
  padding: 0px 30px;
  border-radius: 40px;
  border: 1px solid #d92323;
  background-color: #d92323;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnsavedelete:hover {
  background-color: #bc1e1e;
}

.text-title {
  font-weight: 600;
  color: #505050;
  font-size: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
}

.title {
  font-weight: 600;
  font-size: 25px;
  color: #505050;
  display: flex;
  align-self: center;
}

.background {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding-top: 2em;
  padding-bottom: 2em;
  margin: 2em 0;
}

.input-group-text {
  background-color: #ffffff;
  border: 1px solid #388087;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  align-items: center;
}

.form-control:focus {
  box-shadow: none;
}

/* input select */
.multiselect-dropdown .dropdown-btn {
  display: inline-block !important;
  width: 100% !important;
  border: 1px solid #388087 !important;
  border-radius: 30px !important;
  height: 50px !important;
  padding: 12px 12px !important;
  margin-top: 10px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
  text-align: left !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  background-image: none !important;
  background-color: white;
}

/* button */
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #388087 !important;
  margin-right: 4px;
  margin-bottom: 4px;
  background: #388087 !important;
  padding: 5px 5px !important;
  color: #fff;
  border-radius: 12px !important;
  float: left;
}

/* arrow */
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 20px !important;
  padding: 8px 8px !important;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}

table {
  overflow: auto;
}

.container-fluid {
  padding: 2.5rem;
}
.container {
  padding-top: 2.5rem;
}

.modal-content {
  border-radius: 40px;
}

.badge {
  background: var(--unnamed-color-def3f5) 0% 0% no-repeat padding-box;
  background: #def3f5 0% 0% no-repeat padding-box;
  border-radius: 30px;
  color: black;
  padding: 10px 15px;
}

th {
  padding: 10px;
}

td {
  padding: 10px;
  background-color: #f8f8f8;
}

td {
  border: solid 0px #000;
  border-style: none solid solid none;
  padding: 10px;
}

tr {
  border-radius: 10px;
}

tr td {
  border-top-style: solid;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.ngx-pagination {
  padding-left: auto !important;
}

.ngx-pagination .current,
.btn-info {
  background: #388087 !important;
  border: transparent !important;
  border-radius: 50% !important;
  text-decoration: none !important;
}

.ngx-pagination .current:hover {
  background: #388087 !important;
  border-radius: 50% !important;
  border: transparent !important;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}
.mat-dialog-container {
  width: 100vw !important;
  border-radius: 20px !important;
  max-width: 500px;
}
.mat-select-panel mat-option.mat-option {
  overflow: visible;
  line-height: initial;
  word-wrap: break-word;
  white-space: pre-wrap;
}
